import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@popmenu/common-ui';

import { FormattedMessage } from 'react-intl';
import useVariable from '../../../../utils/useVariable';
import { preventNegative } from '../../../../utils/events';
import { toInt } from '../../../../utils/numbers';
import { classNames, makeStyles } from '../../../../utils/withStyles';
import { readableFontColor, getReadability } from '../../../../utils/colors';

import CheckBox from '../../../../admin/shared/forms/CheckBox';
import Divider from '../../../../admin/shared/forms/Divider';
import Field from '../../../../admin/shared/forms/Field';
import NumberInputField from '../../../../admin/shared/forms/NumberInputField';
import NestedCollection from '../../../../admin/shared/forms/NestedCollection';
import TextField from '../../../../admin/shared/forms/TextField';

const styles = theme => ({
  extraContainer: {
    '&.modern-extra-modifier': {
      '& > div': {
        '& > label': {
          margin: 0,
        },
        display: 'flex',
        justifyContent: 'center',
        margin: 0,
      },
      '& > p': {
        width: '100%',
      },
      '& div[field="quantity"]': {
        marginRight: theme.spacing(2),
      },
      flexDirection: 'row-reverse',
    },
    '&:last-child': {
      borderBottom: 0,
      marginBottom: 0,
    },
    alignItems: 'center',
    borderBottom: '1px solid #E7E7E7',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  extraCount: {
    alignItems: 'flex-end',
    color: 'rgb(117, 117, 117)',
    display: 'flex',
    flexDirection: 'column',
    float: 'right',
    marginLeft: theme.spacing(0.5),
    padding: theme.spacing(0.5),
  },
  extraCountError: {
    color: '#FF0000',
  },
  extraError: {
    color: '#FF0000',
    fontWeight: 'normal',
    textTransform: 'none',
  },
  extraGroupContainer: {
    marginBottom: theme.spacing(2),
  },
  heading: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  headingWrapper: {
    borderBottom: '1px solid #E7E7E7',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  highlight: {
    backgroundColor: theme.palette.red.main,
    borderRadius: theme.spacing(0.5),
    color: `${theme.palette.white.main} !important`,
    marginRight: -theme.spacing(0.5),
    paddingLeft: `${theme.spacing(0.5)}px !important`,
    paddingRight: `${theme.spacing(0.5)}px !important`,
  },
  modifierModernSubheading: {
    '& > p': {
      color: '#9E9E9E',
      fontSize: '14px',
      textAlign: 'left',
      width: '100%',
    },
    '& > span': {
      '&.modifier-optional': {
        backgroundColor: '#FFF',
      },
      '&:first-child': {
        fontSize: '18px',
      },
      '&:nth-child(2)': {
        alignItems: 'center',
        backgroundColor: '#eec473',
        borderRadius: '50px',
        display: 'flex',
        fontSize: '12px',
        height: '28px',
        padding: '6px 10px',
      },
      color: '#473F2F',
    },
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
  },
  quantityInput: {
    maxWidth: '200px',
  },
  requiredItemPass: {
    backgroundColor: theme.palette.text.disabled,
    borderRadius: theme.spacing(0.5),
    color: `${theme.palette.white.main} !important`,
    marginRight: -theme.spacing(0.5),
    paddingLeft: `${theme.spacing(0.5)}px !important`,
    paddingRight: `${theme.spacing(0.5)}px !important`,
  },
  selectionText: {
    fontSize: '0.8rem',
  },
  textSecondary: {
    color: getReadability('#FFF', theme.palette.secondary.main) < 1.25 ? `${readableFontColor(theme.palette.secondary.main)} !important` : theme.palette.secondary.main,
  },
});

const useStyles = makeStyles(styles);

const ExtraGroupOptions = ({
  calculateOutOfStock,
  extraGroup,
  minSelectionsCount,
  maxSelectionsCount,
  getExtraTitle,
  modernLayout,
}) => {
  const classes = useStyles();
  const [showQuantityInputIds] = useVariable([]);
  const selectionText = () => {
    const min = minSelectionsCount;
    const max = maxSelectionsCount;
    if (min > 0) {
      // required options
      if (max < 1) {
        // no max
        return `Select at least ${min}`;
      } else if (max === min) {
        // specific amount
        return `Select ${max}`;
      } else {
        // up to a certain amount
        return `Select between ${min} and ${max}`;
      }
    } else if (min < 1 && max > 0) {
      return `Select up to ${max}`;
    }
    return null;
  };
  const showRadio = minSelectionsCount === 1 && maxSelectionsCount === 1;
  const MultiSameSelectionComponent = modernLayout ? NumberInputField : TextField;
  return (
    <NestedCollection field="selectedExtras">
      {({ setFieldValue, values: selectedExtras }) => {
        const selectedCount = selectedExtras.reduce((sum, selectedExtra) => sum + toInt(selectedExtra && selectedExtra.quantity) || 0, 0);
        const isRequired = minSelectionsCount > 0;
        const hasMissingRequiredItems = isRequired && (selectedCount > maxSelectionsCount || selectedCount < minSelectionsCount);
        return (
          <Box>
            <Box className={classes.extraGroupContainer}>
              <Box className={classes.headingWrapper}>
                {modernLayout ? (
                  <div className={classes.modifierModernSubheading}>
                    <span>{extraGroup.name}</span>
                    <span className={isRequired ? undefined : 'modifier-optional'}>{isRequired ? 'Required' : 'Optional'}</span>
                    <p>{selectionText()}</p>
                  </div>
                ) : (
                  <React.Fragment>
                    <Typography className={classes.heading}>
                      {extraGroup.name}
                    </Typography>
                    <Box className={classNames(classes.extraCount)}>
                      <Typography className={classNames(isRequired ? (hasMissingRequiredItems ? classes.highlight : classes.requiredItemPass) : null)}>
                        {isRequired ? 'Required' : 'Optional'}
                      </Typography>
                      <Typography className={classNames(classes.selectionText, hasMissingRequiredItems ? classes.extraError : null)}>
                        {selectionText()}
                      </Typography>
                    </Box>
                  </React.Fragment>
                )}
              </Box>
              {selectedExtras.map((selectedExtra, index) => {
                const extra = extraGroup.extras.find(e => e.id === selectedExtra.extraId);
                const outOfStock = calculateOutOfStock(extra);
                if (!extra) {
                  return null;
                }
                return (
                  <Field key={index} field={index}>
                    <Box className={classNames([classes.extraContainer, modernLayout && 'modern-extra-modifier'])}>
                      <Typography>
                        <label htmlFor={`slides ${extra.name}`} style={{ all: 'unset' }}>
                          {getExtraTitle(extra, outOfStock, true, false)}
                        </label>
                      </Typography>
                      <Box>
                        <Field field="quantity">
                          {({ setValue: setQuantity, value }) => {
                            const quantity = toInt(value) || 0;
                            const maxOptionsSelected = !quantity && !!maxSelectionsCount && selectedCount >= maxSelectionsCount && maxSelectionsCount !== 1;
                            if (extraGroup.multiSameSelection && maxSelectionsCount && maxSelectionsCount > 1) {
                              if (quantity === 0 && showQuantityInputIds.indexOf(extra.id) === -1) {
                                return (
                                  <Button
                                    className={classes.textSecondary}
                                    disabled={outOfStock}
                                    onClick={() => {
                                      if (selectedCount < maxSelectionsCount) {
                                        setQuantity(1);
                                        // Always show quantity input once selected to prevent confusion when focusing input
                                        showQuantityInputIds.push(extra.id);
                                      }
                                    }}
                                    variant="text"
                                  >
                                    <FormattedMessage
                                      id="custom_pages.add"
                                      defaultMessage="Add"
                                    />
                                  </Button>
                                );
                              }
                              return (
                                <MultiSameSelectionComponent
                                  preventNegativeValues
                                  className={classes.quantityInput}
                                  field="quantity"
                                  InputProps={{
                                    inputProps: {
                                      'aria-label': `Edit ${extra.name} quantity`,
                                    },
                                  }}
                                  onChange={(e) => {
                                    setQuantity(e.target.value);
                                  }}
                                  onIncrementDown={() => {
                                    if (quantity && quantity > 0) {
                                      setQuantity(quantity - 1);
                                    }
                                    if (quantity === 1) {
                                      const extraIndex = showQuantityInputIds.indexOf(extra.id);
                                      showQuantityInputIds.splice(extraIndex, 1);
                                    }
                                  }}
                                  onIncrementUp={() => {
                                    if (selectedCount < maxSelectionsCount) {
                                      setQuantity((quantity || 0) + 1);
                                    }
                                  }}
                                  onKeyPress={preventNegative}
                                  showSteppers
                                  type="number"
                                  value={value}
                                />
                              );
                            }
                            return (
                              <CheckBox
                                checked={quantity >= 1}
                                data-cy="extra_checkbox"
                                disabled={maxOptionsSelected || outOfStock}
                                field="quantity"
                                id={`slides ${extra.name}`}
                                onChange={() => {
                                  if (showRadio) {
                                    // Clear out other selected extras when exactly 1 option must be selected
                                    selectedExtras.forEach((selectedExtraValue, i) => {
                                      if (selectedExtraValue.quantity >= 1 && selectedExtraValue.extraId !== extra.id) {
                                        setFieldValue([i], { ...selectedExtraValue, quantity: 0 });
                                      } else if (selectedExtraValue.quantity === 0 && selectedExtraValue.extraId === extra.id) {
                                        setFieldValue([i], { ...selectedExtraValue, quantity: 1 });
                                      }
                                    });
                                  } else if (maxSelectionsCount === 1) {
                                    // Clear out other selected extras and/or existing selection when up to 1 option can be selected
                                    selectedExtras.forEach((selectedExtraValue, i) => {
                                      if (selectedExtraValue.quantity >= 1) {
                                        setFieldValue([i], { ...selectedExtraValue, quantity: 0 });
                                      } else if (selectedExtraValue.quantity === 0 && selectedExtraValue.extraId === extra.id) {
                                        setFieldValue([i], { ...selectedExtraValue, quantity: 1 });
                                      }
                                    });
                                  } else {
                                    setFieldValue([index], { ...selectedExtra, quantity: quantity >= 1 ? 0 : 1 });
                                  }
                                }}
                                radio={showRadio}
                                rootClassName={classes.textSecondary}
                                value="1"
                              />
                            );
                          }}
                        </Field>
                      </Box>
                    </Box>
                  </Field>
                );
              })}
            </Box>
            <Divider />
          </Box>
        );
      }}
    </NestedCollection>
  );
};

ExtraGroupOptions.propTypes = {
  extraGroup: PropTypes.shape({
    extras: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      price: PropTypes.number,
    })),
    name: PropTypes.string,
  }).isRequired,
};

export default ExtraGroupOptions;
